.nav-route {
  height: 54px;
  font-size: large;
  align-items: center;
  color: "inherit";
}

.navbar-brand {
  background-color: #73727210 !important;
}

.nav-route:hover {
  text-decoration: none;
}

.nav-route-active {
  color: #a3a0fb;
  background-color: #f0f0f7;
}

.nav-route-active #left-highlight {
  background-color: #a3a0fb;
}

.nav-route-active #icon {
  color: #a3a0fb !important;
}
