/* .main h5,.main h6{
    color:#cfcfcf;
} */
.box {
  background-color: #fff;
  margin: 10px;
  border-radius: 30px;
  padding: 40px;
  box-shadow: 0px 0px 5px 5px #e1e1e1;
  min-height: 100px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box > div {
  display: flex;
  align-items: center;
}
.box h1,
.box h5 {
  color: #fff;
  font-weight: bold;
  margin: 0em 1em;
}
.topnav {
  padding: 1em;
  background: #fff;
  display: flex;
  justify-content: space-around;
  border-radius: 1em;
  margin: 1em;
  box-shadow: 1px 1px 8px 4px #e1e1e1;
}
.topnav > h6 {
  color: #aaaaaa;
  font-weight: bold;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  cursor: pointer;
}
.selectednav {
  background: #e4e4e4;
  color: #010101;
  color: #fff;
}
.leftist h5 {
  color: #5c5b5b;
  font-weight: bold;
}
.leftist h4 {
  color: #5a5a5a;
  font-weight: bold;
}
.top {
  display: flex;
  flex-direction: row;
  padding: 2em 0em;
  justify-content: space-between;
  color: #fff;
}
.top h2 {
  color: #5a5959;
  font-weight: bold;
}
.top a {
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
}
.top > div > a {
  background: #23d098;
  padding: 1em;
  border-radius: 1em;
  color: #fff;
  margin: 1em;
  cursor: pointer;
}
.fields {
  padding: 1.5em 0em;
}
.fields > h5 {
  color: #979797;
}

.fields > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 1em;
  border-radius: 2em;
  margin: 1em;
}
.fields > div > div {
  display: flex;
  word-wrap: break-word;
  align-items: center;
}
.fields > div > div > div {
  word-wrap: break-word;
}
.edit {
  color: tomato;
  font-weight: bold;
  cursor: pointer;
}
.fields > div:nth-child(3) {
  display: flex;
  background: transparent;
  padding: 0em;
}
.fields > div:nth-child(3) > div {
  display: flex;
  background: #fff;
  padding: 1em;
  border-radius: 2em;
  margin-right: 1em;
  flex: 1;
}
.fields img {
  margin: 0em 1em;
}
.fields > div > div > a {
  color: #fff;
  padding: 1em;
}
.second {
  display: flex;
  background: transparent;
  border: 1px solid #000;
}
.viewsection h6 {
  color: #979797;
  font-weight: bold;
}
.perinfo {
  margin: 0em 1em;
  font-weight: 600;
}
.perinfo > div:nth-child(1) {
  font-size: 1.2em;
}
.rightbox::after {
  content: "";
  position: absolute;
  top: 30%;
  left: -2%;
  background: #fff;
  width: 50px;
  height: 40px;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  transform: rotate(90deg);
  box-shadow: 1px 1px 8px 4px #e1e1e1;
}
.nofill {
  flex: 0.25;
  padding: 1em;
  margin: 0.5em 0em;
  text-align: center;
  color: #fff;
  background: linear-gradient(#25e1a4, #21c490);
  border-radius: 1em;
  font-weight: bold;
  width: 6.5em;
  height: 6.5em;
}
.fill {
  flex: 0.25;
  padding: 1em;
  margin: 0.5em 0em;
  text-align: center;
  color: #fff;
  background: linear-gradient(#f56d52, #fd5736);
  border-radius: 1em;
  font-weight: bold;
  width: 6.5em;
  height: 6.5em;
}
.nofill > div,
.fill > div {
  font-size: 0.8em;
}
.topbuts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em;
}
.topbuts > a {
  text-decoration: none;
}
.topbuts > a > div {
  background: #fff;
  padding: 0.5em 1em;
  border-radius: 2em;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 5px 5px #e1e1e1;
}
.topbuts > a > div > div {
  margin: 0em 0.5em;
  /* padding: 0em 1em; */
  font-weight: bold;
}
.mainblock {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}
.cards {
  margin: 1em;
}
.selectedCard {
  border: 4px solid #010101;
  padding: 0.5em;
  border-radius: 3em;
}
.cards > div:nth-child(1) > div {
  /* background: linear-gradient(22.25deg, #FE8B6E 8.33%, #FF6773 83.06%); */
  color: #fff;
  padding: 3em;
  border-radius: 2em;
  font-weight: bold;
  width: 100%;
  box-shadow: 0px 0px 8px 5px #e1e1e1;
}
.recordsec {
  background: #fff;
  border-radius: 3em;
  box-shadow: 0px 0px 8px 5px #e1e1e1;
  padding: 0em 3em;
}
.cards > div > div > div {
  margin: 1em 0em;
}
.cards > div > div > div > div:nth-child(2) {
  border: 1px solid #fff;
  height: fit-content;
  border-radius: 2em;
  padding: 0.5em;
}
.cards img {
  max-width: 3em;
  max-height: 3em;
}
.cards > div:nth-child(1) > div > div:nth-child(1) {
  display: flex;
  justify-content: space-between;
}

.cards > div:nth-child(1) > div > div:nth-child(3) {
  font-size: 1.6em;
}
.header {
  color: #a7a6a6;
  display: flex;
}
.header > div {
  flex: 0.5;
  text-align: center;
  font-size: 1.5em;
}
.entries > div {
  flex: 0.5;
  text-align: center;
  font-size: 1.5em;
  border-bottom: 2px solid #9c9c9c;
  padding: 0.5em 0em;
  color: #838282;
}
.entries {
  display: flex;
}
.topblue {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em 0em;
}
.topblue select {
  /* appearance: none; */
  outline: 1;
  background: transparent;
  background-image: none;
  width: 100%;
  height: 100%;
  color: rgb(95, 95, 95);
  cursor: pointer;
  border: 1px solid #cfcfcf;
  border-radius: 2em;
  padding: 1em;
}
.topblue > a {
  background: #21c490;
  padding: 0.8em;
  border-radius: 1em;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}
.bluebox {
  display: flex;
  justify-content: space-around;
  background: #f4f6f9;
  padding: 2em;
  border-radius: 2em;
  font-weight: bold;
}
.bluebox > h5 {
  font-weight: bolder;
}

.chatside {
  height: 70vh;
  display: flex;
  flex-direction: column;
}

.chatheader {
  padding: 1em;
  border-bottom: 1px solid #f1f1f1;
}
.chatheader > div {
  display: flex;
  flex-direction: row;
  align-content: center;
  font-weight: bolder;
}
.chatheader > div > div:nth-child(1) {
  width: 4em;
  height: 4em;
  border-radius: 50%;
  background: #5c5b5b;
}
.chatheader > div > div:nth-child(2) {
  margin: 0em 1em;
}
.chatheader > div > div:nth-child(2) > div {
  color: #838282;
}
.messagebox {
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 1em;
}
.messagebox > div {
  /* align-self: flex-end; */
  padding: 1em 0em;
}
.messagebox > div > div {
  width: fit-content;
  max-width: 50%;
  font-weight: bold;
}
.messagebox > div > div > div:nth-child(2) {
  float: right;
  font-weight: 400;
}

.reciever {
  background: #f1f1f1;
  border-top-left-radius: 2em;
  border-bottom-right-radius: 2em;
  border-top-right-radius: 2em;
  padding: 2em;
}
.sender {
  background: #3b86ff;
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em;
  border-top-right-radius: 2em;
  padding: 2em;
  color: #fff;
}
.chatinput {
  display: flex;
  flex-direction: row;
  background: #f5f6fa;
  padding: 1em 2em;
  border-radius: 2em;
}
.chatinput > div {
  color: #9c9c9c;
}
.chatinput > input {
  flex: 1;
  border: 0;
  outline: none;
  background: none;
}

.inputfield {
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
}
.inputfield > div:nth-child(1) {
  flex: 1;
  display: flex;
  align-items: center;
}
.inputfield input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
  padding: 1em;
}
.inputfield select {
  padding: 0.5em;
  background: #f4f6f9;
  border: 0;
  font-weight: bold;
}
.formscom {
  display: grid;
  grid-template-columns: auto auto auto auto;
}

@media (max-width: 1840px) {
  .topbuts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1em;
  }
  .topbuts > a {
    text-decoration: none;
  }
  .topbuts > a > div {
    background: #fff;
    padding: 0.5em 0.5em;
    border-radius: 2em;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 5px 5px #e1e1e1;
  }
  .topbuts > a > div > div {
    margin: 0em 0.5em;
    /* padding: 0em 1em; */
    font-weight: bold;
    font-size: 0.8em;
  }
}
@media (max-width: 1730px) {
  .topbuts > a > div > div {
    font-size: 0.6em;
  }
  .topnav > h6 {
    color: #aaaaaa;
    font-weight: bold;
    padding: 0.5em 1em;
    border-radius: 0.5em;
    font-size: 1em;
    cursor: pointer;
  }
  .nofill {
    width: 6em;
    height: 6em;
  }
  .fill {
    width: 6em;
    height: 6em;
  }
}
@media (max-width: 1700px) {
  .nofill {
    width: 5.8em;
    height: 5.8em;
    font-size: 0.8em;
  }
  .fill {
    width: 5.8em;
    height: 5.8em;
    font-size: 0.8em;
  }
}

@media (max-width: 1600px) {
  .topnav {
    padding: 1em;
    background: #fff;
    display: flex;
    justify-content: space-around;
    border-radius: 1em;
    margin: 1em;
    box-shadow: 1px 1px 8px 4px #e1e1e1;
  }
  .topnav > h6 {
    color: #aaaaaa;
    font-weight: bold;
    padding: 0.5em 1em;
    border-radius: 0.5em;
    font-size: 1em;
    cursor: pointer;
  }
  .nofill {
    width: 6em;
    height: 6em;
  }
  .fill {
    width: 6em;
    height: 6em;
  }

  .formscom {
    grid-template-columns: auto auto auto;
  }
}

.detailbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #272d5c;
  color: #fff;
  padding: 1em 2em;
  border-radius: 2em;
  font-weight: bold;
  margin: 1em 0em;
}
.actionsec {
  display: flex;
  color: #fff;
  text-align: end;
  align-content: flex-end;
  justify-content: flex-end;
}
.actionsec > a {
  padding: 1em 2em;
  border-radius: 1em;
  color: #fff;
  font-weight: bold;
  margin: 1em;
}
.actionsec > a:nth-child(2) {
  background: #23d098;
}
.actionsec > a:nth-child(1) {
  background: #a01027;
}
.mediDetails {
  display: grid;
  grid-template-columns: auto auto auto;
  color: #272d5c;
}
.mediDetails > div {
  padding: 2em;
  text-align: center;
}
.mediDetails h6 {
  font-weight: bold;
}
.patdet {
  display: grid;
  grid-template-columns: auto auto auto;
}
.patdet > div {
  text-align: center;
}
