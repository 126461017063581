.fieldsection {
  padding: 1em;
}
.fieldsection > div {
  display: flex;
  /* padding: .5em; */
  width: 100%;
}

.fieldsection > input input {
  /* min-width: "75%"; */
  /* flex: 0.5; */
  padding: 1em;
  /* background: #f1f1f1;
  outline: none;
  margin: 0.5em;
  border: none;
  border-radius: 1em;
  font-size: 1.2em; */
}

.fieldsectiondiv {
  flex: 0.5;
  padding: 1em;
  background: #f1f1f1;
  outline: none;
  margin: 0.5em;
  border: none;
  border-radius: 1em;
  font-size: 1.2em;
}

.tablediv {
  max-height: 50em;
  overflow-y: scroll;
}
table td {
  padding: 1em;
  border-bottom: 1px #f3f3f3 solid;
}
.tablehead {
  background: #f5f6fa;
  color: #939393;
  padding: 1em;
}
.tablehead > th {
  color: #939393;
  padding: 1em;
}
