::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  width: 5px;
  background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  width: 1em;
  background-color: #ddd;
  outline: 1px solid slategrey;
  border-radius: 1rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Montserrat-Semibold;
  src: url(./assets/fonts/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: Montserrat-Thin;
  src: url(./assets/fonts/Montserrat-Thin.ttf);
}

@font-face {
  font-family: Nunito-Bold;
  src: url(./assets/fonts/Nunito-Bold.ttf);
}

@font-face {
  font-family: Nunito-BoldItalic;
  src: url(./assets/fonts/Nunito-BoldItalic.ttf);
}

@font-face {
  font-family: Nunito-Italic;
  src: url(./assets/fonts/Nunito-Italic.ttf);
}

@font-face {
  font-family: Nunito-Light;
  src: url(./assets/fonts/Nunito-Light.ttf);
}

@font-face {
  font-family: Nunito-LightItalic;
  src: url(./assets/fonts/Nunito-LightItalic.ttf);
}

@font-face {
  font-family: Nunito-Regular;
  src: url(./assets/fonts/Nunito-Regular.ttf);
}

@font-face {
  font-family: Nunito-SemiBold;
  src: url(./assets/fonts/Nunito-SemiBold.ttf);
}

@font-face {
  font-family: Nunito-SemiBoldItalic;
  src: url(./assets/fonts/Nunito-SemiBoldItalic.ttf);
}

@font-face {
  font-family: SourceSansPro-Regular;
  src: url(./assets/fonts/SourceSansPro-Regular.ttf);
}

#rememberMe .MuiFormControlLabel-label {
  font-family: "SourceSansPro-Regular" !important;
  font-size: 16 !important;
  color: #4d4f5c !important;
}

.react-pdf__Page__canvas {
  margin-left: auto;
  margin-right: auto;
}
